<template>
<div>
  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>

  <v-card>
   <v-card-title>
     My coupons used:
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-card-text>
     <v-layout row wrap>
       <h2>{{code}}</h2>
       <v-spacer></v-spacer>
       <v-menu
      :close-on-content-click="false"
      v-model="menu1"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
    >
      <v-text-field
        slot="activator"
        v-model="min"
        label="Start"
        prepend-icon="event"
        readonly
      ></v-text-field>
      <v-date-picker v-model="min" @input="menu1 = false"></v-date-picker>
    </v-menu>
    <v-menu
     :close-on-content-click="false"
     v-model="menu2"
     :nudge-right="40"
     lazy
     transition="scale-transition"
     offset-y
     full-width
     min-width="290px"
   >
     <v-text-field
       slot="activator"
       v-model="max"
       label="End"
       prepend-icon="event"
       readonly
     ></v-text-field>
     <v-date-picker v-model="max" @input="menu2 = false"></v-date-picker>
   </v-menu>
     </v-layout>

     <v-sparkline
         :labels="labels"
         :value="values"
         color="primary"
         line-width="1"
         padding="24"
         class="px-5"
       ></v-sparkline>
   </v-card-text>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="orders"
     item-key=".key"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <td>{{props.item.confirmTimestamp | datef}}</td>
       <td>{{ props.item.discount }}</td>
       <td class="">{{ props.item.coupon }}</td>
       <td class="">{{ props.item.price }}</td>
       <td class="">{{ props.item.roomNumber }}</td>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>

</v-container>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Users',
  data: () => ({
    headers: [
      { text: 'Date Used', value: 'confirmTimestamp' },
      { text: 'Coupon Value', value: 'discount' },
      { text: 'Coupon Code', value: 'coupon'},
      { text: 'Total room price', value: 'price' },
      { text: 'Your commission', sortable:false }
    ],
    labels2: [
        '12am',
        '3am',
        '6am',
        '9am',
        '12pm',
        '3pm',
        '6pm',
        '9pm'
    ],
    values2: [
        200,
        675,
        410,
        390,
        310,
        460,
        250,
        240
    ],
    chunk:0,
    dataPoints:8,
    min:moment().subtract(1,"M").format("YYYY-MM-DD"),
    max:moment().format("YYYY-MM-DD"),
    labelFormat:"MM/DD",
    menu1:false,
    menu2:false,
    search: null,
    searched: [],
    users: [],
  }),
  firebase() {
    return {
      orders: {
        source:firebase.database().ref('completedOrders').orderByChild('coupon').equalTo(this.code),
        readyCallback:function(){

        }
      }
    }
  },
  methods: {
  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    code(){
      if (this.$route.query.c!=undefined && this.user.roleValue>70) {
        return this.$route.query.c
      } else {
        if (this.user.code!=undefined){
          return this.user.code
        } else {
          return "No Coupon Registered"
        }
      }
    },
    labels(){
      let t=[]
      let minVal=moment(this.min,"YYYY-MM-DD").valueOf()
      let maxVal=moment(this.max, "YYYY-MM-DD").valueOf()
      let diff = maxVal-minVal  //get the difference between start and end date
      console.log(diff,minVal, maxVal);
      this.chunk = diff/(this.dataPoints-1)  //set size of 1 chunck.
      for (var i = 0; i < this.dataPoints; i++) {
        t.push(moment(minVal+i*this.chunk).format(this.labelFormat)) //add each chunk to the labels
      }
      return t
    },
    values(){
      let u={}
      console.log(this.chunk);
      for (var i = 0; i < this.orders.length; i++) {
        for (var j = 0; j < this.labels.length; j++) {
          if (moment(this.labels[j],this.labelFormat).subtract(this.chunk).isBefore(moment(this.orders[i].confirmTimestamp)) && moment(this.orders[i].confirmTimestamp).isBefore(moment(this.labels[j],this.labelFormat)) ) {
            if (u[j]!=undefined){
              u[j]=u[j]+1
            } else {
              u[j]=1
            }
          }
        }

      }
      console.log(u);
      let v=[]
      for (var k = 0; k < this.labels.length; k++) {
        if (u.hasOwnProperty(k)){
          v[k]=u[k]
        } else {
          v[k]=0
        }
      }
      return v
    }
  },
  created() {
    console.log('get user');
    // this.refreshUser()
  },
  filters: {
    datef: function(t){
      return moment(t).format("LL")
    }
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
